import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import bglogin from '../imgs/bg-login.png'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
const axios = require('axios');

const Login = () => {
    //const [inputState,setInputState]=useState(false)
    const [cin, setCin] = useState('')
    const [demande, setDemande] = useState('')
    const [loader, setLoader] = useState(false)
    let history = useHistory();
    React.useEffect(() => {
        if (localStorage.getItem('token'))
            history.push('/app')
    }, [])

    return (
        <div className='contentLogin'>
            <div className="container">
                <div>
                    <h1>ACCÉDER  À VOTRE INTERFACE PERSONNELLE </h1>
                    <p>L’accès à vos données est totalement sécurisé à travers notre plateforme en ligne</p>
                </div>
                <div className="bgLogin">
                    <div className="bgLogininside">

                    </div>
                    <div className="formLogin">
                        <p>Saisir les coordonnées de votre compte pour avoir les informations
        relatives à votre demande</p>
                        <form id="login" onSubmit={
                            (e) => {
                                e.preventDefault()
                                if (!demande || !cin) return;
                                setLoader(true)

                                axios.post("https://manageapi.chery-tunisie.tn/clientAuth", { "num": demande, "CIN": cin })
                                    .then(data => {
                                        console.log(data)
                                        localStorage.setItem('token', JSON.stringify(data.data.token))
                                        localStorage.setItem('cin', JSON.stringify(cin))
                                        localStorage.setItem('demande', JSON.stringify(demande))
                                        history.push('/app/')
                                        setLoader(false)

                                    }).catch(err => setLoader(false))
                            }
                        }>
                            <input type="number" placeholder="Numéro de demande *" value={demande} onChange={e => setDemande(e.target.value)} required></input>
                            <input type="number" placeholder="Numéro de carte d’identité *" value={cin} onChange={e => setCin(e.target.value)} required></input>
                            {
                                !loader ?
                                    <input type="submit" value="Accéder au compte"></input> :
                                    <LoadingOutlined style={{ fontSize: 24 }} spin />
                            }
                        </form>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Login;