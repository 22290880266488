import React from 'react';
// import './fonts/HelveticaNeue Medium.ttf'
import logo from './logo.svg';
import Layout from './components/Layout';
import HomeContent from './components/HomeContent';
import Login from './components/Login';
import Stepper from './components/Stepper';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import './App.css';

function App() {
  return (

    <div className="App">
      <Router>
        <Layout>

          <Switch>
            <Route exact path='/'>
              <HomeContent />
            </Route>
            <Route path='/login'>
              <Login />
            </Route>
            <Route path='/app/'>
              <Stepper />
            </Route>
          </Switch>
        </Layout>
      </Router>

    </div>


  );
}

export default App;
