import React, { useState } from 'react';
import 'antd/dist/antd.css';
// import '../index.css';
import { Steps, Button, message } from 'antd';
import axios from 'axios';
import { useHistory, withRouter } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

const { Step } = Steps;

const Stepper = () => {

  const [current, setCurrent] = useState(0)
  const [steps, setsteps] = useState([])
  const [remarque, setremarque] = useState('')
  const [data, setdata] = useState(null)
  const history = useHistory()

  React.useEffect(() => {
    if (!localStorage.getItem('token'))
      history.push('/login')

    axios.get('https://manageapi.chery-tunisie.tn/getClientAfterAuth/' + JSON.parse(localStorage.getItem('demande')) + '/' + JSON.parse(localStorage.getItem('cin')), {
      headers: {
        Authorization: `Bearer ` + JSON.parse(localStorage.getItem('token'))
      }
    })
      .then(res => {
        // const steps = res.data.map(el => ({ title: el.titre, content: el.nom, id: el._id }))
        setsteps(res.data.listEtats)
        setremarque(res.data.dossiers.Remarques)
        res.data.listEtats.forEach((el, i) => {
          if (el._id == res.data.dossiers.etat_actuel.etatId) {
            setCurrent(i)
          }
        })

        //   this.setState({ remarque: JSON.parse(localStorage.getItem('dataUser')).client.dossiers.find(el => el.num == JSON.parse(localStorage.getItem('demande'))) && JSON.parse(localStorage.getItem('dataUser')).client.dossiers.find(el => el.num == JSON.parse(localStorage.getItem('demande'))).Remarques })
        console.log(res)
        setdata(res.data)
      })
  }, [])

  return (
    <>
      { data ?
        <div className={data.dossiers.etat_actuel.signe == "positif" ? "stepperChery" : "stepperChery err"}>
          <div className="container stepper">
            <h2>Bonjour {data.prenom} {data.nom}</h2>

            <div className="steps-content mobile">
              {data.dossiers.etat_actuel.etatId == "5f70beadca93fb1cf4a8f3a0" &&
                <div>
                  <img style={{ width: 100 }} src={require('../imgs/CaptureInscription.PNG')} />
                  <p className="etat">INSCRIPTION EFFECTUÉE</p>
                  <p style={{ fontSize: 20, marginTop: 10, color: "#051F61" }}>{remarque}</p>
                </div>
              }
              {data.dossiers.etat_actuel.etatId !== "5f70beadca93fb1cf4a8f3a0" &&
                <div>
                  <img style={{ width: 100 }} src={data.dossiers.etat_actuel.signe == "positif" ? require('../imgs/etapevalide.png') : require('../imgs/etapenonvalide.png')} />
                  {data.dossiers.etat_actuel.signe == "positif" ? <p className="etat">DOSSIER VALIDÉ</p> :
                    <p className="etat">DOSSIER NON VALIDE</p>}
                  <p style={{ fontSize: 20, marginTop: 10, color: "#051F61" }}>{remarque}</p></div>
              }
            </div>
            <Steps current={current}>
              {steps.map((item, i) => (
                <Step key={item.id} title={item.titre} />
              ))}
            </Steps>

            <div className="steps-content desktop">
              {data.dossiers.etat_actuel.etatId == "5f70beadca93fb1cf4a8f3a0" &&
                <div>
                  <img style={{ width: 100 }} src={require('../imgs/CaptureInscription.PNG')} />
                  <p className="etat">INSCRIPTION EFFECTUÉE</p>
                  <p style={{ fontSize: 20, marginTop: 10, color: "#051F61" }}>{remarque}</p>
                </div>
              }
              {data.dossiers.etat_actuel.etatId !== "5f70beadca93fb1cf4a8f3a0" &&
                <div>
                  <img style={{ width: 100 }} src={data.dossiers.etat_actuel.signe == "positif" ? require('../imgs/etapevalide.png') : require('../imgs/etapenonvalide.png')} />
                  {data.dossiers.etat_actuel.signe == "positif" ? <p className="etat">DOSSIER VALIDÉ</p> :
                    <p className="etat">DOSSIER NON VALIDE</p>}
                  <p style={{ fontSize: 20, marginTop: 10, color: "#051F61" }}>{remarque}</p></div>
              }
            </div>
          </div>
        </div> :
        <LoadingOutlined style={{ fontSize: 24 }} spin />

      }
    </>
  );

}

export default withRouter(Stepper);