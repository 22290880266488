import React, { useEffect, useState } from 'react';
import flag from '../imgs/flag.png';
import fb from '../imgs/fb.svg';
import insta from '../imgs/insta.svg';
import youtube from '../imgs/youtube.svg';
import { withRouter} from "react-router-dom"


const Footer = (props)=>{
    const [isHome, setisHome] = useState(false)
    //console.log(props.location.pathname)  
    const detectHome = ()=>{
        if (props.location.pathname ==="/") {setisHome(true)}
        else {setisHome(false)}
    } 
    useEffect(()=>{
        detectHome()
     
    })
    
    console.log(isHome)
    return(
        <div className="cheryfooter">

             { isHome && <div className='sectionNews'>
                <div className='container News'>
                    <h3>Obtenez les dernières nouvelles</h3>
                    <p>N'hésitez pas à vous inscrire à notre newsletter pour recevoir toutes les nouveautés CHERY.</p>
                    <form action="#" id='newsLetter'>
                        <div  className='customInput'>
                            <input type='email' placeholder='Votre adresse E-mail'></input>
                            <input type="submit" value="S'incrire"></input>
                        </div>
                    </form>
                </div>
            </div>
            }
            <div className="container">
                <div className="firstRow">
                    <div className="oneColumn">
                        <h3>À PROPOS DE NOUS</h3>
                        <p>CHERY Tunisie ( STA )</p><p> Suivez-nous sur</p>
                        <ul>
                            <li><img src={fb}></img></li>
                            <li><img src={insta}></img></li>
                            <li><img src={youtube}></img></li>
                            
                        </ul>
                    </div>
                    <div className="twoColumn">
                        <h3>LIENS UTILES</h3>
                        <ul>
                            <li>Actualité</li>
                            <li>Technologies</li>
                            <li>Contact</li>
                            <li>FAQ</li>
                        </ul>
                    </div>
                    <div className="threeColumn">
                        <h3>CONTACT</h3>
                        <p>contact@chery-tunisie.tn</p>
                        <p style={{color: "#E02236",fontWeight: "600"}}>STA, Société Tunisienne d'automobile</p>
                        <p>Tunis : ZI Borj Ghorbel,، Ben Arous, Tunisia </p>
                        <p>Nabeul: Route de Tunis Km 3 8000 Nabeul </p>
                        <p>Sfax: 30 rue Taieb Mhiri immeuble 4 Saison</p>

                    </div>
                </div>
                
                <div className="secondRow">
                    <div> <img src={flag}></img> TUNISIE</div>
                    <div className="copyright">
                        <span>2020 Business. All Rights Reserverd.</span>                
                        <span>Privacy Policy  </span>             
                        <span>Terms and Conditions</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default withRouter( Footer)