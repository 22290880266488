import React, { useState } from 'react';
import QQ from '../imgs/QQ.png';
import connect from '../imgs/connect.png';
import pop from '../imgs/pop.png';
import bgpop from '../imgs/bg-pop.png';
import { useHistory } from 'react-router-dom';


const HomeContent = () => {
    let history = useHistory();

    const [inputState, setInputState] = useState(false)
    React.useEffect(() => {
        if (localStorage.getItem('token'))
            history.push('/app')
    }, [])
    return (
        <div className='Hcontent'>
            <div className='sectionOne'>
                <div className="container">
                    <div className='headSec'>
                        <h1>SUIVRE EN LIGNE VOTRE DEMANDE</h1>
                        <p>Ce service, prensenté par  CHERY TUNISIE,  permet de faire le suivi en ligne de votre </p>
                        <p>demande d’acquisition de la voiture populaire CHERY QQ.</p>
                    </div>
                    <div className='headImg'>
                        <img src={QQ}></img>
                    </div>
                </div>
            </div>

            <div className='sectionTwo'>
                <div className="container two">
                    <div className='connnecttxt'>
                        <h2>RESTEZ CONNECTÉ</h2>
                        <p>Une fois que vous avez complété et déposé votre dossier chez l’un de nos conseillers clients, vous aurez un accès direct à notre plateforme. Toutes les informations relatives à l’avancement de votre dossier vous seront notifiées par email et/ou par téléphone. </p>
                        <a>VOIR ESPACE CLIENT</a>
                    </div>
                    <div className='connect'>
                        <img src={connect}></img>
                    </div>
                </div>
            </div>
            <div className='sectionThree'>
                <div className="container three">
                    <div className='popImg'>
                        <img src={pop}></img>
                    </div>
                    <div className='poptxt'>
                        <h2>Voiture Populaire Chery.</h2>
                        <p>Si vous n'êtes pas encore inscrit, L’équipe CHERY vous aider pas à pas pour réussir votre demande d’acquisition de la voiture populaire CHERY   QQ. Vous trouverez toutes les informations nécessaires sur notre rubrique guide d’inscription. Une fois vos documents complets il ne vous reste plus qu’à déposer votre dossier chez l’un des concessionnaires CHERY le plus proche de chez vous.</p>
                        <a>GUIDE INSCRIPTION</a>
                    </div>
                </div>
            </div>
            {/* <div className='sectionNews'>
                <div className='container News'>
                    <h3>Obtenez les dernières nouvelles</h3>
                    <p>N'hésitez pas à vous inscrire à notre newsletter pour recevoir toutes les nouveautés CHERY.</p>
                    <form action="#" id='newsLetter'>
                        <div style={ inputState === true ? {borderColor: "#132C6A"}: {}} className='customInput'>
                            <input onFocus={()=>setInputState(true)} onBlur={()=>setInputState(false)} type='email' placeholder='Votre adresse E-mail'></input>
                            <input type="submit" value="S'incrire"></input>
                        </div>
                    </form>
                </div>
            </div> */}

        </div>
    );


}

export default HomeContent;