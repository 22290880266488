import React from 'react';
import { Link, useHistory } from "react-router-dom";

import logo from '../imgs/chery.png';
const axios = require('axios');

const Header = () => {
    const [token, setToken] = React.useState(localStorage.getItem('token') || 'noToken')
    React.useEffect(() => {
        // Listen for changes to the current location.
        const unlisten = history.listen((location, action) => {
            // location is an object like window.location
            console.log(
                `The current URL is ${location.pathname}${location.search}${location.hash}`
            )
            console.log(`The last navigation action was ${action}`)
            setToken(localStorage.getItem('token') || 'noToken')
        })
        return function cleanup() {
            // To stop listening, call the function returned from listen().
            unlisten()
        }
    })
    const history = useHistory()
    return (
        <div className="header">
            <div className="container">
                <header className="cheryHeader">
                    <div className="contentHeader">
                        <div className="logochery"><img src={logo}></img></div>
                    </div>
                    <div className="cheryMenu">
                        <ul>
                            {token == 'noToken' ?
                                <><li><Link to='/'>Guide Inscription</Link></li>
                                    <li><Link to='/login'>Suivre Votre Demande</Link></li></>
                                :
                                <li style={{ cursor: "pointer" }} onClick={() => {

                                    axios.post('https://manageapi.chery-tunisie.tn/clientLogout', { demande: JSON.parse(localStorage.getItem('demande')), cin: JSON.parse(localStorage.getItem('cin')) }, {
                                        headers: {
                                            Authorization: `Bearer ` + JSON.parse(localStorage.getItem('token'))
                                        }
                                    }).then(() => {
                                        console.log('111')
                                        localStorage.removeItem('token')
                                        setToken('noToken')
                                        history.push('/')
                                    })
                                }}>Se deconnecter</li>
                            }
                        </ul>

                    </div>
                </header>
            </div>

        </div>);
}


export default Header;